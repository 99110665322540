import React, { Component } from "react";
import Layout from "../BasicComp/Layout";
import Breadcrumb from "../BasicComp/Breadcrumb";
import Button from "react-bootstrap/Button";
import Footer from "../BasicComp/Footer";
import axios from "axios";
import CardDetails from "./CardDetails";
import BlogPreview from "./BlogPreview";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import MetaTags from "react-meta-tags";
import { send } from "emailjs-com";

import v1 from "../../img/its-1.png";
import v2 from "../../img/its-2.png";
import v3 from "../../img/its-3.png";
import v4 from "../../img/cloud2.png";
import v5 from "../../img/v5.png";
import cta from "../../img/cta_separator.png";
import cta2 from "../../img/cta2.jpg";

import ITSolutionv1 from "../../img/ITSoultion-v1.png";
import APIV1 from "../../img/API-v1.png";
// import Cloud from "../../img/cloud2.png"
import Cloud from "../../img/CloudV1.jpg";

import CMSV1 from "../../img/CMSV1.png";
// import ITSolutionv1 from "../../img/ITSoultion-v1.png"

import CustomModal from "./CustomModal";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default class ITServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      isIT: false,
      showCards: true,
      service: "",
      nameValid: true,
      emailValid: true,
      showModal: false,
      disabled: true,
      modalTitle: "",
      selectedPlan: "",
      email: {
        User_Name: "",
        User_Email: "",
      },
    };
    this.ChoosePlan = this.ChoosePlan.bind(this);
  }

  onContact() {
    this.setState({
      showModal: true,
      modalTitle: "Free Advice",
      selectedPlan: null,
    });
  }

  hideModal() {
    this.setState({
      showModal: false,
    });
  }

  ChoosePlan = (planName) => {
    this.setState({
      showModal: true,
      modalTitle: "Choose Plan",
      selectedPlan: planName,
    });
  };

  componentDidMount() {
 
    // if (window.location.protocol !== "https:") {
    //   window.location.replace(
    //     `https:${window.location.href.substring(
    //       window.location.protocol.length
    //     )}`
    //   );
    // }
    // axios
    //   .get(
    //     "http://public-api.wordpress.com/rest/v1/sites/samwcoding.wordpress.com/posts"
    //   )
    //   .then((res) => {
    //     this.setState({ posts: res.data.posts });
    //   })
    //   .catch((error) => console.log(error));

    window.scrollTo(0, 0);
  }

  cardBackClick() {
    this.setState({
      isIT: false,
      showCards: true,
      service: "",
    });
  }

  toggleCards = (item) => {
    if (item == "IT") {
      this.setState({
        isIT: true,
        showCards: false,
        service: "IT",
      });
    } else if (item == "API") {
      this.setState({
        isIT: true,
        showCards: false,
        service: "API",
      });
    } else if (item == "CMS") {
      this.setState({
        isIT: true,
        showCards: false,
        service: "CMS",
      });
    } else if (item == "Cloud") {
      this.setState({
        isIT: true,
        showCards: false,
        service: "Cloud",
      });
    } else {
      this.setState({
        isIT: false,
      });
    }
  };

  render() {
    const items = [
      { text: "Home", url: "/" },
      { text: "IT Services", url: "/it-services" },
    ];
    
    var verticalCard1 = {
      width: "100%",
      padding: "0",
      // backgroundImage: `url(${v1})`,
      // backgroundImage: `url(${ITSolutionv1})`,
      backgroundImage: `url(itservice-webp/ITSoultion-v1.webp)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
    var verticalCard2 = {
      width: "100%",
      padding: "0",
      // backgroundImage: `url(${v2})`,
      // backgroundImage: `url(${APIV1})`,
      backgroundImage: `url(itservice-webp/API-V1.webp)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
    var verticalCard3 = {
      width: "100%",
      padding: "0",
      // backgroundImage: `url(${v3})`,
      // backgroundImage: `url(${CMSV1})`,
      backgroundImage: `url(itservice-webp/CMSV1.webp)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
    var verticalCard4 = {
      width: "100%",
      padding: "0",
      // backgroundImage: `url(${v4})`,
      // backgroundImage: `url(${Cloud})`,
      backgroundImage: `url(itservice-webp/CloudV1.webp)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
    var verticalCard5 = {
      width: "100%",
      padding: "0",
      backgroundImage: `url(${v5})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
    var ctaseparator = {
      width: "100%",
      padding: "0",
      backgroundImage: `url(${cta2})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };

    return (
      <>
        <MetaTags>
          <title>Idea to Infinite - IT Services</title>
          <meta
            name="description"
            content="Idea to Infinite gives a scope of Consulting, IT Leadership, and Management Services that enable the business to set the right foot forward. Creating digital solutions for you."
          />
          <meta
            property="og:title"
            content="Creating Digital Solutions – The Last IT Partner You'll ever need"
          />
          <meta property="og:image" content="images/itt.png" />
        </MetaTags>

        <div style={{ zIndex: "0" }}>
          <Layout history={this.props.history}></Layout>
        </div>
        <div style={{ zIndex: "0" }}>
          <div
            className="col-md-12 col-xs-12 col-sm-12 col-lg-12"
            style={{
              backgroundImage: `url(images/ITServiceBanner.png)`,
              height: "585px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            {/* <img
              src={"images/ITServiceBanner.png"}
              className="banner-image-contact"
              alt="Creating Digital Solutions"
            ></img> */}

            {/* <div className="row bannerTextBg m-0">
             */}
             <div className="bannerMod">
              <div className="banner-text">
                <h1 className="responsive-headline">
                  Creating Digital Solutions
                  <br />
                  The Last IT Partner You'll Ever Need
                </h1>
                <h2 className="text-white p-0 pt-2">
                  We bring you a different range of IT Services starting from IT
                  Solutioning, API Services, CMS and Cloud Services to help you
                  lead your business processes while accelerating the revenue
                  growth.
                </h2>
              </div>
              <div className="CTAButton text-center mt-4">
                {/* <HashLink to="/digital">
                  <Button
                    variant="primary"
                    style={{ marginRight: "2%", borderRadius: "15px 0" }}
                  >
                    See What We Do
                  </Button>
                </HashLink> */}
                <Button
                  variant="outline-light"
                  style={{ borderRadius: "15px 0" }}
                  onClick={this.onContact.bind(this)}
                >
                  Get Free Advice
                </Button>
              </div>
            </div>
          </div>
          <Breadcrumb items={items} />
          <div className="row m-0 iti_secondsec">
            {/* add pt-2 */}
            <div className="col-md-5 col-xs-12 col-sm-12 p-5 pb-3 pt-3 order-md-last iti_secimg iti_imgsec">
              <img
                src={"images/itt.png"}
                className="iti_secimg"
                alt="Accelerate Your Digital Journey"
              ></img>
            </div>

            <div className="col-md-5 offset-md-1 col-xs-12 col-sm-12 pt-3 pb-3 sectionPadding order-md-first iti_para">
              <h3 className="sectionHeadText2">
                Accelerate Your Digital Journey - The Right Powerful IT
                Solutions
              </h3>
              <p className="sectionParaText w-100">
                We can suggest IT best practices just to guide you on how best
                you can use existing and forthcoming opportunities in the area
                of innovation. By curating a powerful IT roadmap, we can assist
                you with benefiting as much as possible from innovation
                advancements to more readily address the issues of your clients,
                catch a bigger piece of the market share, as well as grow
                revenue.
              </p>
              <p className="sectionParaText w-100">
                “Revolve your world around the customers and more customers will
                revolve around you” - Heather Williams
              </p>
            </div>
          </div>
          <div className="row m-0">
            <div
              className="col-xs-12 col-sm-12 text-center"
              style={{ padding: "2% 5%" }}
            >
              <h3 className="sectionHeadText2">IT Services</h3>
              <p
                className="sectionParaText w-100 pt-2 text-center"
                id="cardHeader2"
              >
                Idea to Infinite is an expert with sound experience in
                Information Technology alongside Digital Transformation. We
                bring you different range of IT Services starting from IT
                Solutioning, API Services, CMS and Cloud Services to help you
                lead your business processes while accelerating the revenue
                growth.
              </p>
            </div>
          </div>

          <div
            className={`row m-0 p-0 ${
              this.state.showCards ? "" : "vcardDisplay"
            }`}
          >
            <div
              className="verticalCard col-md col-sm-12 itService_cards"
              style={verticalCard1}
              // onClick={() => this.toggleCards("IT")
            >
              <Link to="./it-services/it-solutioning">
                <span className="vcardText">
                  IT Solutioning
                  <p className="text-white vcardSubText">
                    Our cutting-edge IT solutions help you accelerate your
                    growth.
                  </p>
                  <p className="exploreMore">
                    Explore More
                    <img
                      src={"Icons/Back.svg"}
                      className="iconNext"
                      alt="next"
                    ></img>
                  </p>
                </span>
                <div className="overlay h-100 w-100 m-0"></div>
              </Link>
            </div>

            <div
              className="col-md col-sm-12 verticalCard itService_cards"
              style={verticalCard2}
              onClick={() => this.toggleCards("API")}
            >
              <Link to="./it-services/api">
                <span className="vcardText">
                  API Services
                  <p className="text-white vcardSubText">
                    Our API Services are developed to communicate easily and
                    securely.
                  </p>
                  <p className="exploreMore">
                    Explore More
                    <img
                      src={"Icons/Back.svg"}
                      className="iconNext"
                      alt="next"
                    ></img>
                  </p>
                </span>
                <div className="overlay h-100 w-100 m-0"></div>
              </Link>
            </div>
            <div
              className="col-md col-sm-12 verticalCard itService_cards"
              style={verticalCard3}
              onClick={() => this.toggleCards("CMS")}
            >
              <Link to="./it-services/cms">
                <span className="vcardText">
                  CMS
                  <p className="text-white vcardSubText">
                    Our CMS solution will turn your organization’s content
                    challenges into a viable advantage.
                  </p>
                  <p className="exploreMore">
                    Explore More
                    <img src={"Icons/Back.svg"} className="iconNext"></img>
                  </p>
                </span>
                <div className="overlay h-100 w-100 m-0"></div>
              </Link>
            </div>
            <div
              className="col-md col-sm-12 verticalCard itService_cards"
              style={verticalCard4}
              onClick={() => this.toggleCards("Cloud")}
            >
              <Link to="./it-services/cloud">
                <span className="vcardText">
                  Cloud Services
                  <p className="text-white vcardSubText">
                    Our Cloud solutions will help you to scale your business
                    rapidly with future proof technology.
                  </p>
                  <p className="exploreMore">
                    Explore More
                    <img
                      src={"Icons/Back.svg"}
                      className="iconNext"
                      alt="next"
                    ></img>
                  </p>
                </span>
                <div className="overlay h-100 w-100 m-0"></div>
              </Link>
            </div>
            {/*
              <div className="col-md col-sm-12 verticalCard" style={ verticalCard5 }>
                <span className="vcardText">
                  Sample Services
                  <p className="text-white vcardSubText">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>
                </span>
                <div className="overlay h-100 w-100 m-0">
                </div>
              </div>
              */}
          </div>

          {this.state.service ? (
            <div
              className={`row m-0 p-0 ${
                this.state.showCards ? "vcardDisplay" : ""
              }`}
            >
              <CardDetails
                service={this.state.service}
                backClick={this.cardBackClick.bind(this)}
              />
            </div>
          ) : null}

          <div
            className={`row m-0 p-0 ${
              this.state.showCards ? "" : "vcardDisplay"
            }`}
          >
            <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 sectionPadding text-center">
              <p
                className="sectionParaText w-100 p-2 pb-2"
                style={{ fontSize: "16px", paddingBottom: "1rem !important" }}
              >
                Our abilities and solid innovation associations have given us
                the fortitude to plan and foster a cutting-edge insight and
                automation layer around your current applications. Our answers
                are esteem accretive, optimal risk, higher ROI, and assist you
                with reacting expertly to showcase openings, supporting your
                necessities in understanding your key vital business objectives.
              </p>
              <p
                className="sectionParaText w-100 p-2 pt-0"
                style={{ fontSize: "16px" }}
              >
                We comprehend that with regards to information, every
                association has explicit requirements. Accordingly, we tailor
                our experiences administrations to help our customers meet their
                objectives. Regardless of whether you are at the actual
                beginning of your information experiences excursion or
                attempting to change to the following stage, we can assist you
                with interpreting your objectives into a significant guide.Our
                IT Customization and Maintenance Services gives a start to
                finish and practical arrangements across industry verticals to
                assist them with consenting to emerging technological
                difficulties and upgrade business competitiveness. Our solutions
                are catering to all the business areas.
              </p>
            </div>
          </div>

          <div className="row m-0 p-0">
            <div
              className="col-xs-12 col-sm-12 p-0 text-center"
              style={ctaseparator}
            >
              <h2 className="text-white p-4 m-0">
                Want A Website? Start Here!
              </h2>
            </div>

            <div
              className="col-xs-12 col-sm-12 text-center"
              style={{ padding: "6% 2% 1% 2% !important" }}
            >
              <h3
                className="sectionHeadText2 pt-4"
                style={{ paddingTop: "1.5rem" }}
              >
                Our Plans
              </h3>
              <p className="sectionParaText w-100 p-2 pt-2 text-center">
                Have a question about which plans to choose from? Need an expert
                opinion — or just want to find out more about pricing, <br />
                we’d love to hear from you. Reach us at{" "}
                {process.env.REACT_APP_MobileNO} or drop a line at{" "}
                {process.env.REACT_APP_EMAIL}
              </p>
            </div>
          </div>

          <div className="container">
            <div className="row m-0 mb-5 p-0">
              <div
                className="col-md-4 col-sm-12 text-center planCard"
                style={{
                  borderLeft: "6px solid #0C8AFF",
                  paddingBottom: "5%",
                }}
              >
                <img
                  src={"Icons/Home/Our Price/Basic.svg"}
                  className="price-icon"
                  alt="Our plan"
                ></img>
                <h2 className="p-5 pt-2 planHead">Basic</h2>
                <ul
                  className="planList"
                  style={{ listStyleType: "none", padding: "0 5%" }}
                >
                  <li>Single Page Data</li>
                  <li>Static Data</li>
                  <li>Responsive</li>
                  <li>Contact Form</li>
                  <li>Social Media Links Integration</li>
                  <li>Hosting</li>
                </ul>
                <h2
                  className="p-5 pb-4 pt-2 planHead"
                  style={{ fontWeight: "800" }}
                >
                  $200
                </h2>

                <Button
                  variant="outline-dark"
                  className="LearnButton"
                  onClick={() => {
                    this.ChoosePlan("Basic");
                  }}
                >
                  Choose Plan
                </Button>
              </div>
              <div
                className="col-md-4 col-sm-12 text-center planCard"
                style={{
                  borderLeft: "6px solid #014EFF",
                  paddingBottom: "5%",
                }}
              >
                <img
                  src={"Icons/Home/Our Price/Professional.svg"}
                  className="price-icon"
                  alt="Our Price"
                ></img>
                <h2 className="p-5 pt-3 planHead">Premium</h2>
                <ul
                  className="planList"
                  style={{ listStyleType: "none", padding: "0 5%" }}
                >
                  <li>Upto 8 Pages</li>
                  <li>Design Mockups(1 revision)</li>
                  <li>Responsive</li>
                  <li>Basic SEO</li>
                  <li>Payment Integration</li>
                  <li>Hosting</li>
                </ul>
                <h2
                  className="p-5 pb-4 pt-2 planHead"
                  style={{ fontWeight: "800" }}
                >
                  $450
                </h2>

                <Button
                  variant="outline-dark"
                  className="LearnButton"
                  onClick={() => {
                    this.ChoosePlan("Premium");
                  }}
                >
                  Choose Plan
                </Button>
              </div>
              <div
                className="col-md-4 col-sm-12 text-center planCard"
                style={{
                  borderLeft: "6px solid #031A8F",
                  paddingBottom: "5%",
                }}
              >
                <img
                  src={"Icons/Home/Our Price/Premium.svg"}
                  className="price-icon"
                  alt="Our Price"
                ></img>
                <h2 className="p-5 pt-2 planHead">Professional</h2>
                <ul
                  className="planList"
                  style={{ listStyleType: "none", padding: "0 5%" }}
                >
                  <li>Upto 12 Pages</li>
                  <li>Design Mockups(Upto 2 Revisions)</li>
                  <li>Wireframe</li>
                  <li>Dynamic Data</li>
                  <li>Google Analytics</li>
                  <li>Hosting and more..</li>
                </ul>
                <h2
                  className="p-5 pb-4 pt-2 planHead"
                  style={{ fontWeight: "800" }}
                >
                  Custom Pricing
                </h2>
                <Button
                  variant="outline-dark mb-5"
                  className="LearnButton"
                  onClick={() => {
                    this.ChoosePlan("Professional");
                  }}
                >
                  Choose Plan
                </Button>
              </div>
            </div>
          </div>

          <div className="row m-0 p-0" style={ctaseparator}>
            <div className="col-md-12 col-sm-12 p-0 text-center">
              <h2 className="text-white p-4 pl-2 pr-2 m-0">
                Want To Get A Step Ahead? Get Started With Our Custom IT
                Solutions.
              </h2>
            </div>
          </div>

          <div className="row m-0 p-0 showPosts">
            <div className="col-xs-12 col-sm-12 sectionPadding pt-5 text-center">
              <h3 className="sectionHeadText2 pt-4">Recent Posts</h3>
              <p className="sectionParaText w-100 p-5 pb-2 pt-2 text-center">
                Business and technology news from around the world. Exclusive
                stories and <br />
                expert analysis on digital transformation, technology, designing
                and more.
              </p>
            </div>
          </div>

          <div className="container showPosts">
            <div className="row m-0">
              {this.state.posts.slice(1).map((post) => (
                <BlogPreview post={post} />
              ))}
            </div>
          </div>
        </div>

        {/* <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 footerBg"> */}
          <Footer history={this.props.history} />
        {/* </div> */}

        {this.state.showModal ? (
          <CustomModal
            showModal={this.state.showModal}
            onClose={this.hideModal.bind(this)}
            selectedService={this.state.selectedPlan}
            title={this.state.modalTitle}
          ></CustomModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}
