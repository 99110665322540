import React, { Component } from "react";
import Layout from "../BasicComp/Layout";
import Button from "react-bootstrap/Button";
import Footer from "../BasicComp/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import CardDetails from "./CardDetails";
import BlogPreview from "./BlogPreview";
import CustomServices from "./CustomServices";
import { HashLink } from "react-router-hash-link";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import MetaTags from "react-meta-tags";
import { send } from "emailjs-com";

import v1 from "../../img/E1.png";
import v2 from "../../img/E2.png";
import v3 from "../../img/E3.png";
import v4 from "../../img/E4.png";
import v5 from "../../img/E5.png";
import cta from "../../img/cta_separator.png";
import cta3 from "../../img/cta3.jpg";

import AccountingV1 from "../../img/Accounting-v1.png";
import Branding from "../../img/Branding-v1.png";
import Marketing from "../../img/Marketing-v1.png";
import Legal from "../../img/LegalV1.png";
import ITSolutionv1 from "../../img/ITSoultion-v1.png";

import CustomModal from "./CustomModal";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Breadcrumb from "../BasicComp/Breadcrumb";

export default class DigitalServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      isIT: false,
      showCards: true,
      service: "",
      nameValid: true,
      emailValid: true,
      showModal: false,
      disabled: true,
      email: {
        User_Name: "",
        User_Email: "",
      },
    };
  }

  onContact() {
    this.setState({
      showModal: true,
    });
  }

  hideModal() {
    this.setState({
      showModal: false,
    });
  }

  componentDidMount() {
    // if (window.location.protocol !== "https:") {
    //   window.location.replace(
    //     `https:${window.location.href.substring(
    //       window.location.protocol.length
    //     )}`
    //   );
    // }
    //   axios
    //     .get(
    //       "http://public-api.wordpress.com/rest/v1/sites/samwcoding.wordpress.com/posts"
    //     )
    //     .then((res) => {
    //       this.setState({ posts: res.data.posts });
    //     })
    //     .catch((error) => console.log(error));

    window.scrollTo(0, 0);
  }

  cardBackClick() {
    this.setState({
      isIT: false,
      showCards: true,
      service: "",
    });
  }

  toggleCards = (item) => {
    if (item == "ACCOUNTING") {
      this.setState({
        isIT: true,
        showCards: false,
        service: "ACCOUNTING",
      });
    } else if (item == "IT") {
      this.setState({
        isIT: true,
        showCards: false,
        service: "IT",
      });
    } else if (item == "BRANDING") {
      this.setState({
        isIT: true,
        showCards: false,
        service: "BRANDING",
      });
    } else if (item == "LEGAL") {
      this.setState({
        isIT: true,
        showCards: false,
        service: "LEGAL",
      });
    } else if (item == "MARKETING") {
      this.setState({
        isIT: true,
        showCards: false,
        service: "MARKETING",
      });
    } else {
      this.setState({
        isIT: false,
      });
    }
  };

  render() {
    const items = [
      { text: "Home", url: "/" },
      { text: "Digital Transformation Services", url: "/digital-transformation-services" },
    ];
    var verticalCard1 = {
      width: "100%",
      padding: "0",
      // backgroundImage: `url(${v1})`,
      // backgroundImage: `url(${AccountingV1})`,
      backgroundImage: `url(digitalservice-webp/Accounting-v1.webp)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
    var verticalCard2 = {
      width: "100%",
      padding: "0",
      // backgroundImage: `url(${v2})`,
      // backgroundImage: `url(${Legal})`,
      backgroundImage: `url(digitalservice-webp/LegalV1.webp)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
    var verticalCard3 = {
      width: "100%",
      padding: "0",
      // backgroundImage: `url(${v3})`,
      backgroundImage: `url(digitalservice-webp/E3.webp)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
    var verticalCard4 = {
      width: "100%",
      padding: "0",
      // backgroundImage: `url(${ITSolutionv1})`,
      backgroundImage: `url(digitalservice-webp/ITSoultion-v1.webp)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
    var verticalCard5 = {
      width: "100%",
      padding: "0",
      // backgroundImage: `url(${v5})`,
      backgroundImage: `url(digitalservice-webp/E5.webp)`,
      // backgroundImage: `url(${Marketing})`,

      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
    var ctaseparator = {
      width: "100%",
      padding: "0",
      backgroundImage: `url(${cta3})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };

    return (
      <>
        <MetaTags>
          <title>
            Idea to Infinite - End-to-End Digital Transformation Services
          </title>
          <meta
            name="description"
            content="The IT Application Services practice at Idea to Infinite is focused on providing a unique service made possible by our digital capabilities. Uncover the potential of digital and build a future ready business."
          />
          <meta
            property="og:title"
            content="Uncover the Potential of Digital - Build a Future-Ready Business"
          />
          <meta property="og:image" content="images/it_girl.png" />
        </MetaTags>

        <div style={{ zIndex: "0" }}>
          <Layout history={this.props.history}></Layout>
        </div>
        <div style={{ zIndex: "0" }}>
          <div
            className="col-md-12 col-xs-12 col-sm-12 col-lg-12"
            style={{
              // backgroundImage: `url(images/eteBanner.png)`,
              backgroundImage: `url(digitalservice-webp/eteBanner.webp)`,
              height: "585px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            {/* <img
              src={"images/eteBanner.png"}
              className="banner-image-contact"
              alt="End-To-End Digital Transformation Services"
            ></img> */}

            {/* <div className="row bannerTextBg m-0"> */}
            <div className="bannerMod">
              <div className="banner-text">
                <h1 className="responsive-headline">
                  End-To-End Digital Transformation Services
                </h1>
                <h2 className="text-white p-0 pt-2">
                  As a top-notch Digital Transformation company providing end to
                  end services, we desire to take your organization to the next
                  level, bridging the gap between you and the success you
                  desire.
                </h2>
              </div>
              <div className="CTAButton text-center mt-4">
                {/* <HashLink to="/services">
                  <Button
                    variant="primary"
                    style={{ marginRight: "2%", borderRadius: "15px 0" }}
                  >
                    See What We Do
                  </Button>
                </HashLink> */}

                <Button
                  variant="outline-light"
                  style={{ borderRadius: "15px 0" }}
                  onClick={this.onContact.bind(this)}
                >
                  Get Free Advice
                </Button>
              </div>
            </div>
          </div>
          <Breadcrumb items={items}/>
          <div className="row m-0">
            <div className="col-md-5 col-xs-12 col-sm-12 p-5 pb-3 pt-2 order-md-last digital_SecImg">
              <img
                // src={"images/it_girl.png"}
                src={"digitalservice-webp/it_girl.webp"}
                className=""
                alt="The IT Application Services practice"
              ></img>
            </div>
            <div className="col-md-5 offset-md-1 col-xs-12 pt-3 pb-3 col-sm-12 sectionPadding order-md-first digital_Sec">
              <h3 className="sectionHeadText2">
                Uncover the Potential of Digital! <br />
                Build a Future Ready Business
              </h3>
              <p className="sectionParaText w-100">
                The journey to Digital Transformation with complete end-to-end
                services made possible on a single platform. Focus on your
                customers while getting in touch with a single point of contact
                to lead you towards global recognition. Making businesses
                self-reliant and cope up with the ever changing market needs
                along with the hassle free process of getting the things done
                with inclusive services all together on a single platform.
              </p>
            </div>
          </div>
          <div className="row m-0">
            <div
              className="col-xs-12 col-sm-12 text-center"
              id="endtoend"
              style={{ padding: "2% 5%" }}
            >
              <h3 className="sectionHeadText2">
                End-To-End Digital Transformation
              </h3>
              <p className="sectionParaText w-100 pt-2 text-center">
                Idea to Infinite is a one stop destination for all the services
                required for customers to go digital. Beginning from Company
                Inception, obtaining Legal Rights, Branding, IT solutioning to
                Marketing, all under the same roof.
              </p>
            </div>
          </div>

          <div
            className={`row m-0 p-0 ${
              this.state.showCards ? "" : "vcardDisplay"
            }`}
          >
            <div
              className="verticalCard col-md col-sm-12"
              style={verticalCard1}
              onClick={() => this.toggleCards("ACCOUNTING")}
            >
              <Link to="./digital-transformation-services/accounting">
                <span className="vcardText">
                  Accounting
                  <p className="text-white vcardSubText">
                    Wide range of services for all your accounting and
                    compliance needs.
                  </p>
                  <p className="exploreMore">
                    Explore More
                    <img
                      src={"Icons/Back.svg"}
                      className="iconNext"
                      alt="next"
                    ></img>
                  </p>
                </span>
                <div className="overlay h-100 w-100 m-0"></div>
              </Link>
            </div>
            <div
              className="col-md col-sm-12 verticalCard"
              style={verticalCard2}
              onClick={() => this.toggleCards("LEGAL")}
            >
              <Link to="./digital-transformation-services/legal">
                <span className="vcardText">
                  Legal Compliances
                  <p className="text-white vcardSubText">
                    Obtain legal rights while complying with complicated
                    regulations, policies and procedures governing the business
                    operations.
                  </p>
                  <p className="exploreMore">
                    Explore More
                    <img
                      src={"Icons/Back.svg"}
                      className="iconNext"
                      alt="next"
                    ></img>
                  </p>
                </span>
                <div className="overlay h-100 w-100 m-0"></div>
              </Link>
            </div>
            <div
              className="col-md col-sm-12 verticalCard"
              style={verticalCard3}
              onClick={() => this.toggleCards("BRANDING")}
            >
              <Link to="./digital-transformation-services/branding">
                <span className="vcardText">
                  Branding
                  <p className="text-white vcardSubText">
                    Empower your business while sparking a connection with your
                    audience.
                  </p>
                  <p className="exploreMore">
                    Explore More
                    <img
                      src={"Icons/Back.svg"}
                      className="iconNext"
                      alt="next"
                    ></img>
                  </p>
                </span>
                <div className="overlay h-100 w-100 m-0"></div>
              </Link>
            </div>
            <div
              className="col-md col-sm-12 verticalCard"
              style={verticalCard4}
              onClick={() => this.toggleCards("IT")}
            >
              <Link to="./digital-transformation-services/it-solutioning">
                <span className="vcardText">
                  IT Solutioning
                  <p className="text-white vcardSubText">
                    Accelerate your growth with cutting edge IT solutions.
                  </p>
                  <p className="exploreMore">
                    Explore More
                    <img
                      src={"Icons/Back.svg"}
                      className="iconNext"
                      alt="next"
                    ></img>
                  </p>
                </span>
                <div className="overlay h-100 w-100 m-0"></div>
              </Link>
            </div>

            <div
              className="col-md col-sm-12 verticalCard"
              style={verticalCard5}
              onClick={() => this.toggleCards("MARKETING")}
            >
              <Link to="./digital-transformation-services/marketing">
                <span className="vcardText">
                  Marketing
                  <p className="text-white vcardSubText">
                    Reach your audience globally with forever associate while
                    building trust and loyalty.
                  </p>
                  <p className="exploreMore">
                    Explore More
                    <img
                      src={"Icons/Back.svg"}
                      className="iconNext"
                      alt="next"
                    ></img>
                  </p>
                </span>
                <div className="overlay h-100 w-100 m-0"></div>
              </Link>
            </div>
          </div>

          {this.state.service ? (
            <div
              className={`row m-0 p-0 ${
                this.state.showCards ? "vcardDisplay" : ""
              }`}
            >
              <CardDetails
                service={this.state.service}
                backClick={this.cardBackClick.bind(this)}
              />
            </div>
          ) : null}

          <div
            className={`row m-0 p-0 ${
              this.state.showCards ? "" : "vcardDisplay"
            }`}
          >
            <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 sectionPadding text-center">
              <p
                className="sectionParaText w-100 p-2 pb-2"
                style={{ fontSize: "16px" }}
              >
                Digital transformation is a journey consisting of various
                milestones. When a new or existing business aspires to go
                digital, they face the challenge of investing a significant
                amount of time approaching different service providers and a
                lack of technical expertise may impede their productivity
                towards existing clients. Idea to Infinite being the single
                point of contact simplifies the process of going digital with
                our end-to-end services.
              </p>
              <p
                className="sectionParaText w-100 p-2 pt-0"
                style={{ fontSize: "16px" }}
              >
                Starting from Business Inception, Accounting and Legal
                Compliances, Branding, IT solutioning to Marketing, Idea to
                Infinite brings all the services under the same roof with a
                single point of contact makes it easier for the businesses to
                rapidly reduce the effort and go to market time. We create
                tailored solutions for all your business needs. Explore all our
                services today and start your journey for transforming
                digitally.
              </p>
            </div>
          </div>

          <div className="row m-0 p-0">
            <div
              className="col-xs-12 col-sm-12 p-0 text-center"
              style={ctaseparator}
            >
              <h2 className="text-white p-4 m-0">
                Have Custom Requirements? <br className="br" /> Contact Us Now !
              </h2>
            </div>

            <div
              className="col-xs-12 col-sm-12 sectionPadding pt-5 pb-3 text-center"
              style={{ padding: "1% 2% !important" }}
            >
              <h3 className="sectionHeadText2">Our Custom Services</h3>
              <p className="sectionParaText w-100 p-2 pt-2 text-center">
                Prerequisites such as compliance and approvals must be carefully
                maintained to enable a smooth flow. A reliable partner that
                understands the complexities of your supply chain, as well as
                local restrictions, can make the process go more smoothly.
              </p>
            </div>
          </div>

          <div className="container">
            <div className="row m-0 mb-5 p-0">
              <CustomServices />
            </div>
          </div>

          <div className="row m-0 p-0" style={ctaseparator}>
            <div className="col-md-12 col-sm-12 p-0 text-center">
              <h2 className="text-white p-4 pl-2 pr-2 m-0">
                Want To Reach Your Customers Globally?
                <br className="br" /> Go Digital.
              </h2>
            </div>
          </div>

          <div className="row m-0 p-0 showPosts">
            <div className="col-xs-12 col-sm-12 sectionPadding pt-5 text-center">
              <h3 className="sectionHeadText2 pt-4">Recent Posts</h3>
              <p className="sectionParaText w-100 p-5 pb-2 pt-2 text-center">
                Business and technology news from around the world. Exclusive
                stories and <br />
                expert analysis on digital transformation, technology, AI,
                designing and more.
              </p>
            </div>
          </div>

          {/* <div className="container showPosts">
              <div className="row m-0">
                {this.state.posts.slice(1).map((post) => (
                  <BlogPreview post={post} />
                ))}
              </div>
            </div> */}
        </div>

        {/* <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 footerBg"> */}
          <Footer history={this.props.history} />
        {/* </div> */}

        {this.state.showModal ? (
          <CustomModal
            showModal={this.state.showModal}
            onClose={this.hideModal.bind(this)}
            selectedService={this.state.selectArr}
            title="Free Advice"
          ></CustomModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}
