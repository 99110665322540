import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./Components/BasicComp/Layout";
import HomePage from "./Components/Pages/HomePage";
import ITServices from "./Components/Pages/ITServices";
import DigitalServices from "./Components/Pages/DigitalServices";
import DigitalServiceDetial from "./Components/Pages/DigitalServiceDetials";
import WeDo from "./Components/Pages/WeDo";
import ContactUs from "./Components/Pages/ContactUs";
import Blog from "./Components/Pages/Blog";
// import Payment from "./Components/Pages/Payment";
import Email from "./Components/Pages/Email";
import ChatComp from "./Components/Pages/Chat";
import BlogPreviewId from "./Components/Pages/BlogPreviewId";
import NotFound from "./Components/Pages/NotFound";
import Terms from "./Components/Pages/Terms";
import Policy from "./Components/Pages/Policy";
import ServiceDetails from "./Components/Pages/ServiceDetials";
import ServiceCard from "./Components/Pages/ServiceCard";
import DigitaServiceCard from "./Components/Pages/DigitalServiceCard";
import { HashRouter } from "react-router-dom";

const createRoutes = (props) => (
  <Router>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/home" component={HomePage}/>
      {/* <Route exact path="/pay" component={Payment} /> */}
      <Route exact path="/ITIBlog" component={Blog} />
      <Route exact path ="/IT-Services/:id" component={ServiceDetails}/>
      <Route exact path ="/IT-Services/it-solutioning/:id" component={ServiceCard} data="Text"/>
      <Route exact path ="/IT-Services/api/:id" component={ServiceCard} />
      <Route exact path ="/IT-Services/cms/:id" component={ServiceCard}/>
      <Route exact path ="/IT-Services/cloud/:id" component={ServiceCard}/>
      <Route exact path ="/digital-transformation-services/:id" component={DigitalServiceDetial}/>
      <Route exact path ="/digital-transformation-services/accounting/:id" component={DigitaServiceCard}/>
      <Route exact path ="/digital-transformation-services/marketing/:id" component={DigitaServiceCard}/>
      <Route exact path ="/digital-transformation-services/it-solutioning/:id" component={DigitaServiceCard}/>
      <Route exact path ="/digital-transformation-services/legal/:id" component={DigitaServiceCard}/>
      <Route exact path ="/digital-transformation-services/branding/:id" component={DigitaServiceCard}/>

      <Route exact path="/blog/:id" component={BlogPreviewId} />
      <Route exact path="/about" component={WeDo} />
      <Route exact path="/it-services" component={ITServices} />
      <Route
        exact
        path="/digital-transformation-services"
        component={DigitalServices}
      />
      <Route exact path="/contact" component={ContactUs} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/policy" component={Policy} />
      <Route path="/**" component={NotFound} />
      <Route path="*/" component={NotFound} />
    </Switch>
  </Router>
);

export default createRoutes;

